<template>
  <div class="min-h-full overflow-auto bg-page text-dark flex flex-col">


    <Notifications></Notifications>

    <div class=" w-full xl:max-w-[60%] mx-auto flex justify-end p-4">
      <LanguageSwitcher/>
    </div>

    <div class="grow">
    <slot/>
    </div>
    <div class="flex flex-col xl:flex-row justify-between items-center gap-8 p-8 bg-white mt-8">
      <img src="/logo.svg" class="max-w-[150px] max-h-[150px]"/>
      <div class="text-sm text-lighttext">
        Copyright © Ismat Consulting Formation et Conseil une société d' Ismat Group ® Tous droits réservés. Powered by tokiwi
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
</script>